import React, { useState } from "react";

function Footer() {
  return (
  <footer class="section pb-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8 text-md-left text-center">
          <p class="mb-md-0 mb-4" style={{color:"var(--white-color)"}}>Copyright © 2023 Designed and Developed by Perrin01.</p>
        </div>
      </div>
    </div>
  </footer>
  );
}

export default Footer;