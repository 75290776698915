
import React from "react";
import { Link } from "react-router-dom";

function Instructions() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Draft Tool Guide</h2>
                <div class="row">
                  <div class="col-12">
                    <p>Check out the videos below to learn how to set up the tool as a host, run a draft, and find drafts on our <Link to="https://discord.gg/W4nQReN2Hb">Discord server</Link>.</p>
                  </div>
                </div>
                <div class="content">
                  <hr />
                  <h5 class="mb-5">Setting Up the Lorcana Draft Tool as the Host</h5>
                  <div class="row">
                    <div class="col-12">
                      <p>This video shows you how to set up the Lorcana Draft Tool from Limited Ink in order to host a Lorcana draft.  You can find the tool <Link to="/draft-tool">here</Link>.</p>
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/tjcK_G5junk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                  </div>
                  <hr />
                  <h5 class="mb-5">Running a Lorcana Draft with the Limited Ink Draft Tool</h5>
                  <div class="row">
                    <div class="col-12">
                      <p>This video shows the process of running through a draft using our draft tool for Lorcana.</p>
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/YkcNoVoBlio" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                  </div>
                  <hr />
                  <h5 class="mb-5">Find or Host a Draft on Our Discord</h5>
                  <div class="row">
                    <div class="col-12">
                      <p>In this video we cover how to find or host a draft in our Discord using the TourneyBot plugin.  If you want to host a draft make sure you answer the question with "Yes" when you register.  Join our Discord <Link to="https://discord.gg/W4nQReN2Hb">here</Link>.</p>
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/gk3yjUIcel4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Instructions;