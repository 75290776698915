
import React from "react";
import { Link } from "react-router-dom";

function Resources() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Limited Lorcana Resources</h2>
                <div class="row">
                  <div class="col-12">
                    <p>Check out the resources below to learn more about limited Lorcana.</p>
                  </div>
                </div>
                <div class="content">
                  <hr />
                  <h5 class="mb-5">Ravensburger Official Limited Formats</h5>
                  <div class="row">
                    <div class="col-12">
                      <p style={{fontWeight:"bold"}}>Booster Draft</p>
<p>Players will need to be provided with a minimum of four booster packs each to pass cards among them, from which they will build a 40-card deck using any number of different inks (rather than only two inks).
More than four copies of a card with the same full name can be played.</p>
<p style={{fontWeight: "bold"}}>Sealed Deck</p>
<p>Players will need to be provided with a minimum of six booster packs each to use as their individual card pool, from which they will build a 40-card deck using any number of different inks (rather than only two inks).
More than four copies of a card with the same full name can be played.</p>
                    </div>
                  </div>
                  <hr />
                  <a href="https://www.pixelborntcg.com">
                  <h5 class="mb-5">Pixelborn</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="https://www.pixelborntcg.com/images/pixelborn-logo-nobg.png" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>Simulator used to play Lorcana, including the Lorcana limited decks exported from the tool.</p>
                    </div>
                  </div>
                  </a>
                  <hr />
                  <a href="https://infinite.tcgplayer.com/article/The-Lorcana-Booster-Draft-and-Sealed-Deck-Tier-List">
                  <h5 class="mb-5">Frank Karsten's The First Chapter Tier List</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="https://mktg-assets.tcgplayer.com/fit-in/1200x1200/filters:quality(75)/content/lorcana/2023/Aug/The%20Lorcana%20Booster%20Draft%20and%20Sealed%20Deck%20Tier%20List.jpg" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>A guide from MTG Hall of Famer Frank Karsten on his ratings for the cards in the First Chapter set.</p>
                    </div>
                  </div>
                  </a>
                  <hr />
                  <a href="https://fortyborn.podbean.com/">
                  <h5 class="mb-5">Fortyborn Podcast</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="https://deow9bq0xqvbj.cloudfront.net/image-logo/17107130/FortyBorn1400_is2v29_300x300.jpg" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>A podcast focused exclusively on limited Lorcana.</p>
                    </div>
                  </div>
                  </a>
                  <hr />
                  <a href="https://www.youtube.com/@Nivesnerdcorner">
                  <h5 class="mb-5">Nive's Nerd Corner</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="https://yt3.googleusercontent.com/EnheDfSAcYFwfbI2xvIwWQCgwNq59YlVxWZLnlkoffNHvoc4Y-zd-IFW_HXf3d1BpRc9z9RRBZQ=s176-c-k-c0x00ffffff-no-rj" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>YouTube content on Lorcana with a focus on limited, as well as TCG's in general.</p>
                    </div>
                  </div>
                  </a>
                  <hr />
                  <a href="https://www.youtube.com/@LorcanaAcademy">
                  <h5 class="mb-5">Lorcana Academy</h5>
                  <div class="row">
                    <div class="col-xs-12 col-sm-4">
                      <img src="https://yt3.googleusercontent.com/Nwi_kBMBP6N0pn5CIfjOxSiHj5377BMaVzc5uRVD0Xda0QK9CvB7oxHpEvfOFBOjvzIAfbdx=s176-c-k-c0x00ffffff-no-rj" class="img-fluid" />
                    </div>
                    <div class="col-xs-12 col-sm-8">
                      <p>YouTube channel focused on Lorcana, with some content focused on limited.  MTGNerdGirl focuses exclusively on MTG Draft in her main channel.</p>
                    </div>
                  </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Resources;