
import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
	<section class="section">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 text-center">
					<h1 class="section-title" style={{color:"var(--primary-color)"}}>Limited Ink</h1>
          <h3 style={{color:"var(--white-color)"}}>Your source for tools and resorces for playing limited Lorcana formats.</h3>
				</div>
				<div class="col-lg-4 col-sm-6 mb-4">
					<Link to="/draft-tool" class="px-4 py-5 bg-white shadow text-center d-block match-height">
						<i class="ti-view-list-alt icon text-primary d-block mb-4"></i>
						<h3 class="mb-3 mt-0">Draft Tool</h3>
						<p class="mb-0">Use our draft tool to run a 2-6 person Lorcana draft and export to play in Pixelborn.</p>
					</Link>
				</div>
				<div class="col-lg-4 col-dsm-6 mb-4">
					<Link to="/instructions" class="px-4 py-5 bg-white shadow text-center d-block match-height">
						<i class="ti-help-alt icon text-primary d-block mb-4"></i>
						<h3 class="mb-3 mt-0">Draft Tool Guide</h3>
						<p class="mb-0">Learn how to use the tool to run drafts and play in Pixelborn.</p>
					</Link>
				</div>
				<div class="col-lg-4 col-sm-6 mb-4">
					<Link to="/resources" class="px-4 py-5 bg-white shadow text-center d-block match-height">
						<i class="ti-info-alt icon text-primary d-block mb-4"></i>
						<h3 class="mb-3 mt-0">Resources</h3>
						<p class="mb-0">Learn more about playing limited Lorcana formats.</p>
					</Link>
				</div>
			</div>
		</div>
	</section>
  <section>
		<div class="container">
			<div class="row">
				<div class="col-12">
					<div class="section px-3 bg-white shadow text-center">
						<h2 class="mb-4">Want to join a draft, chat with other limited Lorcana players, or ask a question?</h2>
						<Link to="https://discord.gg/W4nQReN2Hb" class="btn btn-primary">Join our Discord</Link>
					</div>
				</div>
			</div>
		</div>
	</section>
  </>
  );
}

export default Home;