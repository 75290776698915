import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import App,{loader as appLoader,} from './App';
import Home from './pages/Home'
import DraftTool from './pages/DraftTool'
import Resources from './pages/Resources'
import Instructions from './pages/Instructions'
import reportWebVitals from './reportWebVitals';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: appLoader,
    children: [
      {
        path: "instructions",
        element: <Instructions />
      },
      {
        path: "resources",
        element: <Resources />
      },
      {
        path: "draft-tool",
        element: <DraftTool />
      },
      {
        index: true, 
        element: <Home /> 
      }
    ]
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
