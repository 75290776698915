
import React from "react";
import { Link } from "react-router-dom";

function DraftTool() {
  return (
    <>
      <section class="single section-sm pb-0">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="p-lg-5 p-4 bg-white">
                <h2 class="mb-5">Draft Tool</h2>
                <div class="row">
                  <div class="col-12">
                    <p>Use our draft tool to run a 2-8 person Lorcana draft and export to play in Pixelborn, or draft solo as practice.  Curretly the tool is using Google Sheets, but it may be expanded to a full web experience in the future.</p>
                    <p>
                    <a role="button" class="btn btn-primary" href="https://docs.google.com/spreadsheets/d/1DvRAi6yDH0pYIOwmmTk1XHZvKb0O4H2i4IfjWczlOjo/edit?usp=sharing">Download the Rise of the Floodborn Draft Tool</a>
                    </p>
                    <p>
                    <a role="button" class="btn btn-secondary" href="https://docs.google.com/spreadsheets/d/1ibq8LDl6siNAQkAkyIzViXTNrBlZ0jjQbQldGG843EM/edit?usp=sharing">Download the First Chapter Draft Tool</a>
                    </p>
                  </div>
                </div>
                <div class="content">
                  <hr />
                  <h5 class="mb-5">Setting Up the Tool to Host a Draft</h5>
                  <div class="row">
                    <div class="col-12">
                      <p>Watch the YouTube video below and follow the steps to get your own version of the sheet ready to host a draft.  To learn how to run a draft and find other players to draft against check out our <Link to="/instructions">Draft Tool Guide</Link>.</p>
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/tjcK_G5junk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DraftTool;