import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header class="shadow-bottom sticky-top bg-white">
    <nav class="navbar navbar-expand-md navbar-dark">
      <div class="container">
        <Link class="navbar-brand px-2" to={'/'} style={{color:"var(--primary-color)"}}><img src="./assets/images/Limited Ink.png" height="50px;" />Limited Ink</Link>
        <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navigation"
          aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse text-center" id="navigation">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <Link class="nav-link" to={'/draft-tool'}>Draft Tool</Link>
            </li>
            <li class="nav-item">
                <Link class="nav-link" to={'/instructions'}>Draft Tool Guide</Link>
            </li>
            <li class="nav-item">
                <Link class="nav-link" to={'/resources'}>Limited Lorcana Resources</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  );
}

export default Header;